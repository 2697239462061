<template>
  <div class="footer-container">
    <!-- 桌面端 -->
    <div class="footer">
      <div class="foo-left">
        <div class="company-info">
          <img :src="footer.logo" @click="goHome()">
          <div class="line" />
          <div class="company">
            <p class="cn">{{ developer.companyCN }}</p>
            <p class="en">{{ developer.companyEN }}</p>
          </div>
        </div>
        <div class="nav">
          <span v-for="(item, index) in navItems" :key="index" class="nav-item">
            <a :href="item.path">{{ item.title }}</a>
            <span v-if="index < navItems.length - 1">|</span>
          </span>
        </div>
      </div>
      <!-- 右侧二维码 -->
      <div v-if="qrcodeItems" class="foo-right">
        <div v-for="(item, index) in qrcodeItems" :key="index" class="wx-item">
          <img :src="item.image">
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <div class="foo-beian">
      <div class="beian">
        <span @click="gotoBeiAn">{{ other.icp }}</span>
        <span>{{ other.copyright }}</span>
        <!--<span @click="gotoBeiAnQuery()">粤公网安备440XXXX200XXXX</span>-->
      </div>
    </div>
    <!-- 移动端 -->
    <div class="footer-mobile">
      <div class="company-info">
        <img :src="footer.logo">
        <div class="line" />
        <div class="company">
          <p class="cn">{{ developer.companyCN }}</p>
          <p class="en">{{ developer.companyEN }}</p>
        </div>
      </div>
      <div class="nav">
        <span v-for="(item, index) in navItems" :key="index" class="nav-item">
          <span />
          <a :href="item.path">{{ item.title }}</a>
        </span>
      </div>
      <!-- 底部二维码 -->
      <!--<div class="f-w">-->
      <!--  <img src="../../src/assets/images/mobile/w1.png">-->
      <!--  <img src="../../src/assets/images/mobile/w2.png">-->
      <!--  <img src="../../src/assets/images/mobile/w3.png">-->
      <!--</div>-->
    </div>
  </div>
</template>
<script>
import store from "@/store"
import { copyright } from '@/utils/common'
import { FOOTER_ITEMS, QRCODE_ITEMS } from "@/settings"
import { mapGetters } from "vuex"
import { actions, getters } from "@/store/helper"

export default {
  name: "MoeFooter",
  data() {
    return {
      navItems: FOOTER_ITEMS,
      qrcodeItems: QRCODE_ITEMS,
    }
  },
  computed: {
    ...mapGetters({
      developer: getters.site.developer,
      footer: getters.site.footer,
      other: getters.site.other,
    }),
  },
  created() {
    store.dispatch(actions.site.fetchEnterprise)
    store.dispatch(actions.site.fetchSite)
  },
  methods: {
    gotoBeiAn() { copyright.gotoBeiAn() },
    gotoBeiAnQuery() { copyright.gotoBeiAn() },
    pushTo(routerName) {
      console.log('测试成功')
      this.$router.push({ name: routerName })
    }
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.footer-container {
  width: 100%;
  height: 314px;
  background: $footer-bg;
  box-sizing: border-box;
  .footer {
    max-width: 1180px;
    height: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    .foo-left {
      flex: 1;
      .company-info {
        display: flex;
        align-items: center;
        img {
          width: 168px;
          height: 68px;
          cursor: pointer;
          object-fit: contain;
        }
        .line {
          width: 1px;
          height: 46px;
          background: $footer-font;
          opacity: 0.2;
          margin: 0px 30px;
        }
        .company {
          .cn {
            font-size: 16px;
            font-weight: 600;
            color: $footer-font;
          }
          .en {
            font-size: 11px;
            font-weight: 400;
            color: #999999;
            margin-top: 8px;
          }
        }
      }
      .nav {
        margin: 40px 17px 0 17px;
        .nav-item {
          a {
            font-size: 16px;
            font-weight: 400;
            color: $footer-font;
            text-decoration: none;
          }
          span {
            margin: 0px 20px;
            font-size: 16px;
            font-weight: 400;
            color: $footer-font;
          }
        }
      }
    }
    .foo-right {
      display: flex;
      align-items: center;
      .wx-item {
        margin-right: 60px;
        &:last-child {
          margin-right: 0px;
        }
        img {
          width: 100px;
          height: 100px;
          border-radius: 7px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  .foo-beian {
    width: 100%;
    height: 50px;
    margin: 0px auto;
    background-color: #161a27;
    .beian {
      max-width: 1180px;
      margin: 0px auto;
      display: flex;
      align-items: center;
      span {
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        font-weight: 400;
        color: #7f8188;
        cursor: pointer;
        &:hover {
          color: #ffffff;
        }
        &:last-child {
          margin-left: 30px;
        }
      }
    }
  }
  .footer-mobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .footer-container {
    width: 100%;
    height: auto !important;
    background: #1f2437;
    box-sizing: border-box;
    .footer {
      display: none;
    }
    .foo-beian {
      display: none;
    }
    .footer-mobile {
      box-sizing: border-box;
      display: block !important;
      padding: 20px;
      .company-info {
        display: flex;
        align-items: center;
        img {
          width: 68px;
          height: 32px;
          cursor: pointer;
          object-fit: contain;
        }
        .line {
          width: 1px;
          height: 26px;
          background: #ffffff;
          opacity: 0.2;
          margin: 0px 15px;
        }
        .company {
          .cn {
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
          }
          .en {
            font-size: 8px;
            font-weight: 400;
            color: #999999;
            margin-top: 8px;
          }
        }
      }
      .nav {
        margin-top: 28px;
        .nav-item {
          a {
            display: block;
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            text-decoration: none;
            margin: 18px 0px;
          }
          span {
            width: 100%;
            height: 1px;
            display: block;
            border-top: 1px solid #494e5e;
          }
        }
      }
      .f-w {
        margin-top: 30px;
        img {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>
